@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("./styles/_scrollBar.scss");

@import "src/styles/_mixins.scss";
@import "src/styles/_mediaQuery.scss";
@import "src/styles/_variables.scss";

:root {
  --font-primary: "ABeeZee";
  --font-secondary: "Montserrat";
  --font-inter: "Inter";
  --font-DMSans: "DM Sans";
  --font-tertiary: "Helvetica";
  --gradient-text: linear-gradient(266.31deg, #ff4581 6.14%, #4388dd 99.41%);
  --background-black: #181818;
  --primary-blue: #4452fe;
  --content-grey: #5c5c5c;
  // Theme based colors
  --primary-bg-clr: #ffffff;
  --secondary-bg-clr: #ffffff;
  --primary-text-clr: #101828;
  --secondary-text-clr: #667085;
  --table-hightlight-clr: #f9fafb;
  --primary-outline-clr: rgba(0, 0, 0, 0.06);
  --primary-btn-clr: #ffffff;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "ABeeZee", "Montserrat", "Roboto", sans-serif, Arial, Helvetica,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  width: 100vw;
  overflow-x: hidden;
}



.gradient__text {
  -webkit-text-fill-color: transparent;
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
}

.gradient__border {
  border: 10px solid;
  border-image: var(--gradient-text);
  border-image-slice: 1;
}

.ptr {
  cursor: pointer;
}

input[type="text"],
textarea {
  width: 100%;
  background: #fefefe;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0.5rem 0rem;
  padding-left: 10px;
}

.fillBtn__blue {
  border: none;
  padding: 10px 30px;
  @include text-size(1rem);
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-family: inherit;
  background: var(--primary-blue);
  margin: 0px 10px;
  @include flex-centered;
  width: fit-content;
  cursor: pointer;
  gap: 0.5rem;

  &:disabled {
    background: #6c6c6c;
    color: #ffffff;
    opacity: 0.7;
    cursor: default;
  }
  &:disabled {
    background: #6c6c6c;
    color: #ffffff;
    opacity: 0.7;
    cursor: default;
  }
  span {
    @include flex-centered;
  }
}

.outlineBtn {
  min-height: 40px;
  background: var(--primary-btn-clr);
  color: var(--primary-text-clr);
  border: 1px solid var(--primary-outline-clr);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0rem 1.2rem;
  cursor: pointer;

  &:hover {
    &:not(:disabled) {
      transform: scale(1.02);
    }
  }

  &:disabled {
    background: #6c6c6c;
    color: #ffffff;
    opacity: 0.7;
    cursor: default;
  }
}

.outlineInput {
  height: 40px;
  width: 100%;
  background: var(--primary-btn-clr) !important;
  border: 1px solid var(--primary-outline-clr) !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 0.9rem;
  color: var(--primary-text-clr);
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

// Dialog CSS
.MuiPaper-root {
  width: 50vw;
  border-radius: 15px !important;
  background: none !important;

  @include screen-size(phone-only) {
    width: max(400px, 90vw);
    margin: 10px !important;
  }

  .MuiDialogTitle-root {
    border-radius: 15px 15px 0px 0px;
    background: #12101a;
    color: white;
  }

  .MuiDialogContent-root {
    // background: #2a2831;
    background: var(--secondary-bg-clr);
    color: var(--primary-text-clr);
    p {
      font-size: 1rem;
      color: inherit;
    }

    small {
      font-size: 0.7rem;
      color: var(--secondary-text-clr);
    }
  }

  .MuiDialogActions-root {
    justify-content: space-between;
    padding: 0.7rem 1.5rem;
    background: var(--secondary-bg-clr);
    color: var(--primary-text-clr);

    .MuiButtonBase-root {
      border: 1px solid var(--primary-600, #7f56d9);
      background: #5454fb;
      padding: 0.5rem 1rem;
      color: white;
      font-size: 1rem;
      font-weight: 500;
      &:disabled {
        background: #000000;
        opacity: 0.4;
      }
    }
  }
}

.progressbar-container {
  background: #80808021;
  border-radius: 10px;
  border-radius: 4px;

  .progressbar-progress {
    background: #047857;
    border-radius: 4px;
  }
}
